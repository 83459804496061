html {
    height: 100%;
}
body {
    background: #333;
    color: #FFF;
    height: 100%;
}
div#root {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

div.game,
div.turn {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}

@media (max-width: 640px) {
    div.game {
        width: 100%;
        height: 400px;
    }
    div.turn {
        display: none;
    }
    div.end {
        height: calc(100% - 400px)!important;
    }
    div.end p {
        line-height: 30px;
        font-size: 20px;
    }
    .square {
        width: 40px!important;
        height: 40px!important;
        margin: 1px;
    }

    .square > div {
        font-size: 28px!important;
    }

    .knight {
        border:3px solid white;
        height: 34px!important;
        width: 34px!important;
    }
}
div.turn {
    font-size: 50px;
}

.board {
    display: flex;
    flex-direction: column;
}

.line {
    display: flex;
}

.square {
    width: 48px;
    height: 48px;
    margin: 1px;
}

.square > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

.line:nth-child(2n) .square:nth-child(2n + 1),
.line:nth-child(2n + 1) .square:nth-child(2n) {
    background-color: #89A;
}

.line:nth-child(2n) .square:nth-child(2n),
.line:nth-child(2n + 1) .square:nth-child(2n + 1) {
    background-color: #CDE;
}

.knight {
    border:3px solid white;
    height: 42px;
    width: 42px;
}

.allowed:not(.played) {
    cursor: pointer;
    position: relative;
}

.allowed:not(.played)::after {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: black;
    content: " ";
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    opacity: 0.1;
}

.played {
    background-color: transparent! important;
    transition: background-color 0.5s;
}

div.end {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    text-align: center;
}

div.end p {
    width: 100%;
}

div.end button {
    background-color: #428BCA;
    border-color: transparent;
    color: #fff;
    cursor: pointer;
    justify-content: center;
    padding: calc(.375em - 1px) .75em;
    text-align: center;
    white-space: nowrap;
    align-items: center;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
}

.lose {
    font-size: 30px;
}

.lose button {
    margin: 30px;
}

.rules {
    line-height: 50px;
    font-size: 25px;
}